<template>
  <div class="main">
    <BlueprintBlackLogo />
    <div class="main-area">
      <div class="uh-oh">Uh-Oh</div>
      <div class="error">{{ getError() }}</div>
      <div class="try-again">Let’s try that again.</div>
      <!-- <div class="button-area"> -->
      <div class="button-area">
        <div @click="getBack()" class="back">Back</div>
        <div @click="handleExternal()" class="support">Support</div>
      </div>
    </div>
    <!-- <div class="support"></div> -->
    <!-- </div> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import BlueprintBlackLogo from "@/components/ui/BlueprintBlackLogo";

// import SimpleInput from "@/components/ui/SimpleInput";
// import thdLogo from "../assets/images/THD_Logo 1.png";

// import AuthAPI from "@/api/AuthAPI";
// import UserAPI from "@/api/UserAPI";
// import { userNames } from "@/store/modules/user";
// import { useCookies } from "vue3-cookies";
const router = useRouter();
const route = useRoute();
const handleExternal = () => {
  window.open("https://support.hdisonboarding.com", "_blank");
};
// const store = useStore();
function getError() {
  return route.params.error;
}
function getBack() {
  router.back();
}
</script>

<style scoped>
.button-area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.back {
  border-radius: 45px;
  background: var(--hd-orange, #f96302);
  display: inline-flex;
  padding: 20px 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  width: 158px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  margin: 15px;
  border: 3px solid var(--hd-orange, #f96302);
}
.support {
  border-radius: 45px;
  border: 3px solid var(--hd-orange, #f96302);
  display: flex;
  width: 158px;
  padding: 20px 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--dark-grey, #5f6562);
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 15px;
  cursor: pointer;
}
.main-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
  font-family: Lato;
}

.try-again {
  color: #5f6562;
  text-align: center;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 75px;
}
.error {
  font-family: Lato;
  font-size: 20px;
}
.uh-oh {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 64px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
}
.main {
  background: #f4f6f5;
  padding: 50px;
}
</style>
